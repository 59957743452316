<template>
    <div class="header_box">
        <div class="cont_list flex">
            <p class="logo"><img src="@/assets/pc/logo.png" alt=""></p>
            <ul class="flex menu_list">
                <li v-if="linkState"><router-link class="activeMenu" to="/index">首页</router-link></li>
                <li v-else><router-link active-class="activeMenu" to="/index">首页</router-link></li>
                <li><router-link active-class="activeMenu" to="/lmgjList">劳模服务队</router-link></li>
                <li><a href="http://dlgjxy.kjcxchina.com" target="_blank">大连工匠学院</a></li>
                <!-- <li><router-link active-class="activeMenu" to="/ytzqList">研讨专区</router-link></li> -->
                <!-- <li><router-link active-class="activeMenu" to="/3">科技学堂</router-link></li>
                <li><router-link active-class="activeMenu" to="/4">后台入口</router-link></li> -->
            </ul>
            <div class="right_btn_cont">
                    
                <div class="userInfo flex" v-if="userInfo">
                    <div class="flex" @click="goUserInfo()">
                        <p class="img"><img src="@/assets/per.png" alt=""></p>
                        <p class="user_name">{{userInfo.name}}</p>
                    </div>
                    <div class="get_out">
                        <ul>
                            <li @click="getOutBtn()">退出登录</li>
                            <!-- <li>退出登录</li>
                            <li>退出登录</li> -->
                        </ul>
                    </div>
                </div>
                <div class="btn_cont flex" v-else>
                    <p class="btn1" @click="loginBtn()">登录</p>
                    <!-- <p class="btn2">注册</p> -->
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "header_box",
  data() {
    return {
        userInfo:'',
        linkState:false
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  },
   watch: {
    $route: {
      handler(newVal,oldVale) {
        console.log(newVal.meta)
        if(!JSON.parse(localStorage.getItem('userInfo'))){
             this.userInfo = ''
        }
        if(newVal&&newVal.meta.title){
            this.linkState=true
        }else{
            this.linkState = false
        }
       
      },
      immediate: true,
    },
  },
  methods: {
    loginBtn(){
       this.$router.push('/login')
    },
    goUserInfo(){
        if(this.userInfo.loginType==1){
            this.$router.push('/userCont/userInfo')
        }
        if(this.userInfo.loginType==2){
            this.$router.push('/userIndex/userInfo')
        }
    },
    // 退出登录
    getOutBtn(){
        localStorage.removeItem('userInfo')
        sessionStorage.removeItem('auth')
        location.reload()
    }
  },
};
</script>
<style scoped lang="scss">
.header_box{
    // background: #333;
    height: 68px;
    // overflow: hidden;
    position: relative;
    z-index: 99;
    box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.1);
    .cont_list{
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        p.logo{
            width: 190px;
            height: 38px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .menu_list{
            flex: 1;
            margin-left: 78px;
            li{
                margin-right: 27px;
                height: 68px;
                line-height: 68px;
                position: relative;
                a{
                    display: block;
                     padding: 0 21px;
                     font-size: 16px;
                    color: #333333;
                }
            }
            .activeMenu{
                color: #FF5F21;
                border-left: 1px solid #F0F0F0;
                border-right: 1px solid #F0F0F0;
            }
            .activeMenu::after{
                content: '';
                position: absolute;
                left: 50%;
                bottom: 10px;
                transform: translateX(-50%);
                width: 36px;
                height: 3px;
                background: #FF5F21;
                border-radius: 2px;
            }
        }
        .right_btn_cont{
            position: relative;
            .btn_cont{
                p{
                    width: 76px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    cursor: pointer;
                }
                p.btn2{
                    background: #FF5F21;
                    color: #fff;
                }
            }

            .userInfo{
                cursor: pointer;
                height: 68px;
               p.img{
                width: 40px;
                height: 40px;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                }
               }
               p.user_name{
                font-size: 14px;margin-left: 7px;
                color: #333333;
               }
            }
            .userInfo:hover{
                .get_out{
                    display: block;
                }
            }
            // 退出登录
            .get_out{
                position: absolute;
                right: -20px;
                top: 58px;
                z-index: 9;
                display: none;
                ul{
                    background: #fff;
                    border-radius: 10px;
                    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
                    padding: 10px 15px;
                   li{
                    font-size: 16px;
                    color: #000;
                    cursor: pointer;
                   }
                }
            }
        }
    }
}
</style>
