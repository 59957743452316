<template>
  <div class="m-home">
    <router-view />
    
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "MHome",
  components: {
    // Login,
    // Tip,
    //   headerMenu,
    //  footMenu,
  },
  watch: {
    $route: {
      handler(newVal) {
        // console.log(newVal)
        // this.head_title = newVal.meta.title
        // const { headerIsShow, footerIsShow } = newVal.meta;
        // this.headerState = headerIsShow ? false : true;
        // this.footerState = footerIsShow ? false : true;
        // document.body.scrollTop = 0;
        // document.documentElement.scrollTop = 0;
        // if(newVal.path !='/dataIndex'){
        //   this.$axios.post(`/study/index/views`).then(res=>{
        //     console.log(res,'res')
        //   })
        // }
      },
      immediate: true,
    },
  },
  data() {
    return {
      head_title:'',
    };
  },
  mounted() {
    
    
  },
  methods: {
    

  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.scroll-box {
  height: 100vh;
  overflow-y: auto;
}
</style>
